<template>
   <div class="p-10">
      <section class="grid grid-cols-1 md:grid-cols-3 gap-5 my-5">
         <router-link :to="{ name: 'Wallet' }" class="group p-5 bg-white shadow cursor-pointer rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-12 h-12 text-gray-300 transition group-hover:text-blue-500"><path fill="currentColor" d="M19,7H18V6a3,3,0,0,0-3-3H5A3,3,0,0,0,2,6H2V18a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V10A3,3,0,0,0,19,7ZM5,5H15a1,1,0,0,1,1,1V7H5A1,1,0,0,1,5,5ZM20,15H19a1,1,0,0,1,0-2h1Zm0-4H19a3,3,0,0,0,0,6h1v1a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V8.83A3,3,0,0,0,5,9H19a1,1,0,0,1,1,1Z"/></svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#8358;{{ user.wallets.ngn_balance || 0 | formatPrice }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">NGN Balance</div>
               </div>
            </div>
         </router-link>
         <router-link :to="{ name: 'Transactions', query: { query: 'deposit' } }" class="group p-5 bg-white shadow cursor-pointer rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-12 h-12 text-gray-300 transition group-hover:text-green-500"><path fill="currentColor" d="M6,11a1,1,0,1,0,1,1A1,1,0,0,0,6,11Zm12,0a1,1,0,1,0,1,1A1,1,0,0,0,18,11Zm2-6H4A3,3,0,0,0,1,8v8a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V8A3,3,0,0,0,20,5Zm1,11a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V8A1,1,0,0,1,4,7H20a1,1,0,0,1,1,1ZM12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"/></svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#8358;{{ dashboardSummery.total_deposit || 0 | formatPrice}}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total Deposit</div>
               </div>
            </div>
         </router-link>
         <router-link :to="{ name: 'Transactions', query: { query: 'withdrawal' } }" class="group p-5 bg-white shadow cursor-pointer rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-12 h-12 text-gray-300 transition group-hover:text-pink-600"><path fill="currentColor" d="M12,12a3,3,0,1,0,3,3A3,3,0,0,0,12,12Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,16Zm-.71-6.29a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21L15,7.46A1,1,0,1,0,13.54,6L13,6.59V3a1,1,0,0,0-2,0V6.59L10.46,6A1,1,0,0,0,9,7.46ZM19,15a1,1,0,1,0-1,1A1,1,0,0,0,19,15Zm1-7H17a1,1,0,0,0,0,2h3a1,1,0,0,1,1,1v8a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H7A1,1,0,0,0,7,8H4a3,3,0,0,0-3,3v8a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V11A3,3,0,0,0,20,8ZM5,15a1,1,0,1,0,1-1A1,1,0,0,0,5,15Z"/></svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#8358;{{ dashboardSummery.total_withdrawal || 0 | formatPrice}}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total Withdrawal</div>
               </div>
            </div>
         </router-link>
         <div class="group p-5 bg-white shadow cursor-pointer rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-300 transition group-hover:text-indigo-500" viewBox="0 0 20 20" fill="currentColor">
                     <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
                     <path fill-rule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clip-rule="evenodd" />
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#8358;0.00
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Total Payout</div>
               </div>
            </div>
         </div>
         <div class="group p-5 bg-white shadow cursor-pointer rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-12 h-12 text-gray-300 transition group-hover:text-indigo-400"><path fill="currentColor" d="M13,16H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2ZM9,10h2a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Zm12,2H18V3a1,1,0,0,0-.5-.87,1,1,0,0,0-1,0l-3,1.72-3-1.72a1,1,0,0,0-1,0l-3,1.72-3-1.72a1,1,0,0,0-1,0A1,1,0,0,0,2,3V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12ZM5,20a1,1,0,0,1-1-1V4.73L6,5.87a1.08,1.08,0,0,0,1,0l3-1.72,3,1.72a1.08,1.08,0,0,0,1,0l2-1.14V19a3,3,0,0,0,.18,1Zm15-1a1,1,0,0,1-2,0V14h2Zm-7-7H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z"/></svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     &#8358;0.00
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Recieved Payment</div>
               </div>
            </div>
         </div>
         <router-link :to="{ name: 'Transactions', query: { query: 'completed' } }" class="group p-5 bg-white shadow cursor-pointer rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-300 transition group-hover:text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     {{ dashboardSummery.total_completed_escrow || 0 }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Completed Escrow</div>
               </div>
            </div>
         </router-link>
         <router-link :to="{ name: 'Transactions', query: { query: 'pending' } }" class="group p-5 bg-white shadow cursor-pointer rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-12 h-12 text-gray-300 transition group-hover:text-yellow-400"><path fill="currentColor" d="M6.8042,15A.99956.99956,0,0,0,5.438,14.63379l-1.73242,1a1.00016,1.00016,0,0,0,1,1.73242l1.73242-1A1.00073,1.00073,0,0,0,6.8042,15ZM3.70557,8.36621l1.73242,1a1.00016,1.00016,0,1,0,1-1.73242l-1.73242-1a1.00016,1.00016,0,0,0-1,1.73242ZM6,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H5A1,1,0,0,0,6,12ZM17.1958,9a1.0006,1.0006,0,0,0,1.36621.36621l1.73242-1a1.00016,1.00016,0,1,0-1-1.73242l-1.73242,1A1.00073,1.00073,0,0,0,17.1958,9ZM15,6.8042A1.0006,1.0006,0,0,0,16.36621,6.438l1-1.73242a1.00016,1.00016,0,1,0-1.73242-1l-1,1.73242A1.00073,1.00073,0,0,0,15,6.8042Zm5.29443,8.82959-1.73242-1a1.00016,1.00016,0,1,0-1,1.73242l1.73242,1a1.00016,1.00016,0,0,0,1-1.73242ZM16.36621,17.562a1.00016,1.00016,0,1,0-1.73242,1l1,1.73242a1.00016,1.00016,0,1,0,1.73242-1ZM21,11H19a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Zm-9,7a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,12,18Zm-3-.8042a.99954.99954,0,0,0-1.36621.36621l-1,1.73242a1.00016,1.00016,0,0,0,1.73242,1l1-1.73242A1.00073,1.00073,0,0,0,9,17.1958ZM12,2a1,1,0,0,0-1,1V5a1,1,0,0,0,2,0V3A1,1,0,0,0,12,2Z"/></svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     {{ dashboardSummery.total_pending_escrow || 0 }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Pending Escrow</div>
               </div>
            </div>
         </router-link>
         <router-link :to="{ name: 'Transactions', query: { query: 'rejected' } }" class="group p-5 bg-white shadow cursor-pointer rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-300 transition group-hover:text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     {{ dashboardSummery.total_rejected_escrow || 0 }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Rejected Escrow</div>
               </div>
            </div>
         </router-link>
         <router-link :to="{ name: 'Transactions', query: { query: 'held' } }" class="group p-5 bg-white shadow cursor-pointer rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-300 transition group-hover:text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     {{ dashboardSummery.total_held_escrow || 0 }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Payment Hold</div>
               </div>
            </div>
         </router-link>
         <router-link :to="{ name: 'Transactions', query: { query: 'disputed' } }" class="group p-5 bg-white shadow cursor-pointer rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-12 h-12 text-gray-300 transition group-hover:text-red-400"><path fill="currentColor" d="M20.64026,15.77179A3.46849,3.46849,0,0,0,21.5,13.5a3.5,3.5,0,0,0-7,0,3.46849,3.46849,0,0,0,.85974,2.27179A4.98821,4.98821,0,0,0,13,20a1,1,0,0,0,2,0,3,3,0,0,1,6,0,1,1,0,0,0,2,0A4.98821,4.98821,0,0,0,20.64026,15.77179ZM18,15a1.5,1.5,0,1,1,1.5-1.5A1.50164,1.50164,0,0,1,18,15ZM6.79327,7.70734,8.793,9.707A.99989.99989,0,0,0,10.207,8.293L9.91406,8h4.17188l-.293.293A.99989.99989,0,1,0,15.207,9.707l1.9997-1.99969a1.00354,1.00354,0,0,0,0-1.41468L15.207,4.293A.99989.99989,0,0,0,13.793,5.707l.293.293H9.91406l.293-.293A.99989.99989,0,0,0,8.793,4.293L6.79327,6.29266a1.00354,1.00354,0,0,0,0,1.41468Zm1.847,8.06445A3.46849,3.46849,0,0,0,9.5,13.5a3.5,3.5,0,0,0-7,0,3.46849,3.46849,0,0,0,.85974,2.27179A4.98821,4.98821,0,0,0,1,20a1,1,0,0,0,2,0,3,3,0,0,1,6,0,1,1,0,0,0,2,0A4.98821,4.98821,0,0,0,8.64026,15.77179ZM6,15a1.5,1.5,0,1,1,1.5-1.5A1.50164,1.50164,0,0,1,6,15Z"/></svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     {{ dashboardSummery.total_disputed_escrow || 0 }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Disputed Transactions</div>
               </div>
            </div>
         </router-link>
         <router-link :to="{ name: 'Transactions', query: { query: 'resolved' } }" class="group p-5 bg-white shadow cursor-pointer rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-12 h-12 text-gray-300 transition group-hover:text-green-300"><path fill="currentColor" d="M20.18,10.19A11.9,11.9,0,0,0,18,10c-.42,0-.83,0-1.24.08a5.91,5.91,0,0,0-1.91-1.65,3.81,3.81,0,0,0,1-2.57,3.86,3.86,0,0,0-7.72,0,3.81,3.81,0,0,0,1,2.57,6.11,6.11,0,0,0-1.91,1.64C6.83,10,6.42,10,6,10a11.9,11.9,0,0,0-2.18.21,1,1,0,0,0-.82,1v8.25a1,1,0,0,0,.36.77,1,1,0,0,0,.82.22A9.75,9.75,0,0,1,6,20.23a9.89,9.89,0,0,1,5.45,1.63h0l0,0,.13.05h0A1.09,1.09,0,0,0,12,22a.87.87,0,0,0,.28-.05l.07,0,.13-.05,0,0h0A9.89,9.89,0,0,1,18,20.23a9.75,9.75,0,0,1,1.82.18,1,1,0,0,0,.82-.22,1,1,0,0,0,.36-.77V11.17A1,1,0,0,0,20.18,10.19ZM12,4a1.86,1.86,0,0,1,0,3.71h0A1.86,1.86,0,0,1,12,4ZM11,19.33a11.92,11.92,0,0,0-5-1.1c-.33,0-.66,0-1,.05V12a9.63,9.63,0,0,1,2.52.05l.11,0A10,10,0,0,1,11,13.33Zm1-7.73a11.77,11.77,0,0,0-1.38-.68l-.06,0c-.33-.13-.66-.26-1-.36A4,4,0,0,1,12,9.69h0a4,4,0,0,1,2.44.85A12.43,12.43,0,0,0,12,11.6Zm7,6.68a11.6,11.6,0,0,0-6,1v-6a9.76,9.76,0,0,1,3.37-1.22l.2,0A9.39,9.39,0,0,1,19,12Z"/></svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     {{ dashboardSummery.total_resolved_escrow || 0 }}
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Resolved Payments</div>
               </div>
            </div>
         </router-link>
         <div class="group p-5 bg-white shadow cursor-pointer rounded-md">
            <div class="flex justify-between">
               <div class="py-4">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12 text-gray-300 transition group-hover:text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
                  </svg>
               </div>
               <div class="text-right">
                  <div class="text-2xl text-gray-700 font-medium mb-8">
                     0
                  </div>
                  <div class="text-gray-400 font-medium text-sm">Support Tickets</div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
   name: 'Dashboard',

   components: {
      
   },

   data() {
      return {
         isLoading: false,
         dashboardSummery: []
      }
   },
   
   methods: {
      ...mapActions('dashboard', ['get_dashboard_summery']),

      async getDashboardSummery() {
         this.isLoading = true

         try {
            let res = await this.get_dashboard_summery()
            this.dashboardSummery = res.data
            this.isLoading = false
         } catch(error) {
            this.isLoading = false
         }
      }
   },
   
   computed: {
      ...mapGetters("auth", ["user"]),
   },

   mounted() {
      this.getDashboardSummery()
   }
}
</script>
